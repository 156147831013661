.workflow-menu {
    bottom: 0px;
    position: fixed;
    transition: bottom .6s;
    z-index: 98;
    background: #fff;
    width: 100%;
    box-shadow: 0 -7px 7px -5px rgba(0,0,0,0.08);
}

.workflow-menu .actions-container {
    margin: 0 auto;
    width: 360px;
}

.workflow-action {
    border-radius: 14px;
    cursor: pointer;
    display: inline-block;
    height: 54px;
    line-height: 54px;
    margin: 13px 0px 13px 13px;
    text-align: center;
    vertical-align: middle;
    width: 164px;
}

.approve {
    background-color: rgb(44, 234, 168);
    color: white;
}

.reject {
    background-color: rgb(234, 89, 44);
    color: black;
}

@media (min-width: 1280px) {
    .workflow-menu {
        width: calc(100% - 235px);
    }
}