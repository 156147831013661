.sales-rep {
  width: 100%;
  margin-top: 84px;
  padding-top: 20px;
}

.sales-rep .content {
  text-align: left;
  margin-top: 30px;
}

.sales-rep-form {
  display: flex;
  flex-direction: column;
  gap: 12px;

  .MuiInputBase-root {
    width: 400px;
  }

  .MuiFormControl-root {
    height: 72px;
  }
}
