body {
    font-family: Roboto, sans-serif;
    margin: 0;
}

.action {
    cursor: pointer;
}

.dialog-actions div {
    cursor: pointer;
    margin-bottom: 15px;
}

.main-container.actions-menu-active {
    margin-bottom: 150px;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

@media (min-width: 1280px) {
    .main-container {
        margin-left: 235px;
    }
}
