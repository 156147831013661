.locations {
    width: 100%;
    margin-top: 84px;
}

.locations .item {
    border-bottom: 1px solid #f1f3f4;
    font-size: 12px;
    color: rgba(0,0,0,0.4);
    padding: 20px 0;
    cursor: pointer;
}

.content {
    margin-top: 84px;
    display: inline-block;
    width: 100%;
}

.locations .deleted {
    color: red;
}

.section {
    margin: 20px 0px;
    border-bottom: 1px solid #FBF6F6;
    width: 100%;
    display: inline-block;
}

form h2 {
    font-weight: normal;
    font-size: 20px;
}

.locations .item.deleted, 
.locations .item.deleted .name,
.locations .item.deleted .summary  {
    background-color: #fafafa;
    color: rgba(0,0,0,0.4);
}