.content {
    margin-top: 84px;
    display: inline-block;
    width: 100%;
}

.section {
    margin: 20px 0px;
    border-bottom: 1px solid #FBF6F6;
    width: 100%;
    display: inline-block;
}

.section.tabs h2 {
    display: inline-block;
    margin-right: 20px;
}

.section .item {
    border-bottom: 1px solid #f1f3f4;
    padding: 20px 0;
    font-size: 18px;
    color: rgb(51, 51, 51)
}

.section .item .cost {
    text-align: right;
    color: rgb(51, 51, 51)
}

form h2 {
    font-weight: normal;
    font-size: 20px;
}

