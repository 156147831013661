.suppliers {
    width: 100%;
    margin-top: 84px;
}

.suppliers .item {
    border-bottom: 1px solid #f1f3f4;
    font-size: 12px;
    color: rgba(0,0,0,0.4);
    padding: 30px 0;
    cursor: pointer;
}

.suppliers .item .name {
    font-size: 16px;
    color: #333333;
}

.action-container {
    height: 56px;	
    width: 56px;	
    border-radius: 16px 0 16px 0;	
    background-color: #EA592C;	
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.08), 0 8px 16px 0 rgba(0,0,0,0.08), 0 26px 32px 0 rgba(0,0,0,0.08);
    position: fixed;
    right: 15px;
    bottom: 15px;
    text-align: center;
}

.action-container .icon {
    color: #fff;
    margin: 15px;
}

@media (min-width: 1280px) {
    .suppliers {
        margin-top: 0px;
    }
}