.syncSummary {
    width: 100%;
    margin-top: 84px;
}

.item {
    border-bottom: 1px solid #f1f3f4;
    font-size: 12px;
    color: rgba(0,0,0,0.4);
    padding: 20px 0;
}

.item .name {
    font-size: 16px;
    color: #333333;
}

.item .error {
    color: red;
}

.item .summary {
    display: inline-block;
    padding: 10px 0 0 0;
}

.item .info {
    font-size:16px;
}