.action-label {
    width: 128px;
}

.actions-menu .icon-container {
    height: 56px;	
    width: 56px;	
    border-radius: 16px 0 16px 0;	
    background-color: #EA592C;	
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.08), 0 8px 16px 0 rgba(0,0,0,0.08), 0 26px 32px 0 rgba(0,0,0,0.08);
    cursor: pointer;
    position: fixed;
    right: 15px;
    bottom: 15px;
    text-align: center;
}

.actions-menu .icon-container .icon {
    color: #fff;
    margin: 15px;
}

.actions-menu .icon-container {
    transition: bottom .6s;
    z-index: 98;
}

.actions-menu .icon-container.active .icon {
    transform: rotate(90deg);
}

.actions-menu .actions {
    position: fixed;
    bottom: -400px;
    width: 100%;
    border-radius: 16px 0 0 0;	
    background-color: #FBF6F6;	
    box-shadow: 0 0 8px 0 rgba(0,0,0,0.08), 0 0 16px 0 rgba(0,0,0,0.08), 0 0 32px 0 rgba(0,0,0,0.08);
    transition: bottom .6s;
    color: #EA592C;
    z-index: 1;
}

.actions-menu .actions .items {
    padding: 35px 0px;
}

.actions-menu .actions.active {
    bottom: 0px;
}

.icons-col {
    margin-left: 12px;
    position: relative;
    top: 6px;
}

.text-right {
    text-align: right;
}

.action-item .action {
    height: 35px;
    display: block;
}

@media (min-width: 1280px) {
    .actions-menu .actions {
        width: calc(100% - 235px);
    }
}