.search-dialog {
    height: 100%;
    width: 0px;
    background-color: #fff;
    transition: right .6s;
    z-index: 100;
    position: fixed;
    top: 0px;
    right: -500px;
    overflow-y: auto;
}

.search-dialog.active {
    right: 0px;
    width: 100%;
    overflow-y: auto;
}

.search-dialog .search-header {
    height: 84px;
    padding-top: 32px;
    text-align: left;
    border-bottom: 1px solid #f1f3f4;
}

.search-dialog .search-header .arrow {
    text-align: center;
}

.search-dialog .content {
    text-align: left;
    margin-top: 30px;
}

.search-dialog .search-header h2 {
    display: inline-block;
    margin: 0;
    font-size: 24px;
    color: #333333;
    text-decoration: none solid rgb(51, 51, 51);
    line-height: 24px;
    font-weight: normal;
    text-align: left;
}

.search-dialog .dialog-actions {
    padding: 50px;
    text-align: right;
}

.search-dialog .dialog-actions div {
    margin-left: 20px;
    display: inline-flex;
    vertical-align: middle;
    align-items: center;
}

.search-dialog .dialog-actions .icon {
    vertical-align: middle;
}

.search-dialog .dialog-actions .close {
    color: rgba(0,0,0,0.4);
    display: none;
}

.search-dialog .filters-action {
    color: #EA592C;
    line-height: 80px;
    text-decoration: none;
    display: inline-block;
    height: 80px;
}

.search-dialog .dialog-section {
    border-bottom: 1px solid #f1f3f4;
}

.search-state {
    width: 100%;
    background: rgba(255,255,255,0.8);
    position: fixed;
    background-size: cover;
    z-index: 99;
    white-space: nowrap;
    border-bottom: 1px solid #f1f3f4;
    height: 50px;
    line-height: 45px;
    top: 124px;
}

.search-state .chip {
    background: #FAECEA;
    margin-right: 5px;
}

.search-state .chip svg {
    color: #EA592C;
}

.search-dialog .clear-date {
    color: rgba(0,0,0,0.4);
    margin-left: 20px;
}

@media (min-width: 1280px) {
    .search-dialog {
        height: 100%;
        box-shadow: rgba(51, 51, 51, 0.16) 0px 0px 16px 0px;
        background-color: #fff;
        border-radius: 16px 0px 0px;
        transition: right .6s;
        z-index: 101;
        position: fixed;
        top: 0px;
        right: -500px;
    }

    .search-dialog.active {
        right: 0px;
        width: 500px;
    }

    .search-dialog.active .arrow svg {
        display: none;
    }

    .search-dialog .close.lg-hidden {
        display: inline-block;
    }

    .search-state .chips {
        margin-left: -22px;
    }
}