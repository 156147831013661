.content {
    margin-top: 84px;
    display: inline-block;
    width: 100%;
}

.supplier {
    width: 100%;
    margin-top: 84px;
}

.supplier .section {
    border-bottom: 1px solid #f1f3f4;
    margin: 0;
    padding-top: 20px;
}

.supplier .item {
    border-bottom: 1px solid #f1f3f4;
    font-size: 12px;
    color: rgba(0,0,0,0.4);
    padding: 40px 0;
    cursor: pointer;
}

.supplier .item .name {
    font-size: 16px;
    color: #333333;
    margin-bottom: 10px;
}

.supplier .item .error {
    color: #f44336;
}

.supplier .summary ul {
    list-style-type: none;
    padding: 0;
}

.supplier .summary li {
    display: inline-block;
    padding: 0 0.6em 0 0;
}

.actions-menu .icons-col {
    text-align: right;
}