.customer {
    margin-top: 124px;
}

.customers {
    width: 100%;
    margin-top: 84px;
    padding-top: 20px;
}

.customers .item {
    border-bottom: 1px solid #f1f3f4;
    font-size: 12px;
    color: rgba(0,0,0,0.4);
    padding: 40px 0;
    cursor: pointer;
}

.customers .item .name {
    font-size: 16px;
    color: #333333;
}

.customers .dispute {
    color: red;
}

.customers .sub-text {
    color: #9F9F9F;
}

.action-container {
    height: 56px;	
    width: 56px;	
    border-radius: 16px 0 16px 0;	
    background-color: #EA592C;	
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.08), 0 8px 16px 0 rgba(0,0,0,0.08), 0 26px 32px 0 rgba(0,0,0,0.08);
    position: fixed;
    right: 15px;
    bottom: 15px;
    text-align: center;
}

.action-container .icon {
    color: #fff;
    margin: 15px;
}

.range-pricings {
    border-bottom: 1px solid #f1f3f4;
}

.range-pricings .item {
    font-size: 12px;
    color: rgba(0,0,0,0.4);
    padding: 20px 0;
    cursor: pointer;
}

.range-pricings .item .name {
    font-size: 16px;
    color: #333333;
}

.range-pricings .summary {
    width: 100%
}

.range-pricings .summary ul {
    list-style-type: none;
    padding: 0px;
    margin-bottom: 0px;
}

.range-pricings .summary li {
    display: inline-block;
    padding: 0;
}

.range-pricings .summary .range-tier {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 2px;
}

.range-pricings .tier, .sub-text .tier {
    font-weight: bold;
}

.MuiExpansionPanelSummary-content > :last-child {
    padding-right: 0 !important;
}

.range-pricing-row .tier-selection {
    text-align: right;
}

.range-pricing-row .range-code {
        font-size: 12px;
        color: rgba(0,0,0,0.4);
}

.range-pricing-row {
    border-bottom: 1px solid #f1f3f4;
    padding: 20px 0;
}

@media (min-width: 1280px) {
    .suppliers {
        margin-top: 0px;
    }
}