
.header {
    width: 100%;
    background: rgba(251,246,246,0.8);
    position: fixed;
    top: 0px;
    background-size: cover;
    z-index: 99;
    height: 84px;
    padding-top: 32px;
    white-space: nowrap;
}

.header h2 {
    display: inline-block;
    margin: 0;
    font-size: 24px;
    color: #333333;
    text-decoration: none solid rgb(51, 51, 51);
    line-height: 24px;
    font-weight: normal;
}

.header .center {
    text-align: center;
}

.tab-nav {
    width: 100%;
    background: rgba(245,239,239,0.8);
    position: fixed;
    top: 84px;
    z-index: 2;
    height: 40px;
    line-height: 40px;
    white-space: nowrap;
}

.tab-nav ul {
    padding: 0;
    margin: 0;
    line-height: 40px;
}

.tab-nav li {
    display: inline-block;
    margin-right: 20px;
    line-height: 20px;
}

.tab-nav li a {
    font-size: 16px;
    font-weight: 300;
    color: rgba(0,0,0,0.4);
    text-decoration: none;
    cursor: pointer;
}

.tab-nav li a.active {
    color: #ea592c;
}

@media (min-width: 1280px) {
    .header {
        margin-left: -20px;
    }
    
    .header .menu-icon {
        visibility: hidden;
    }

    .tab-nav.expand-lg {
        top: 0px;
        height: 124px;
        background: rgba(251,246,246,0.8);
        font-size: 24px;
    }

    .tab-nav.expand-lg ul {
        padding: 0;
        padding-top: 40px;
        margin-left: -22px;
    }

    .tab-nav ul {
        margin-left: -22px;
    }

    .tab-nav.expand-lg li a {
        font-size: 24px;
    }

    .header.hide-lg {
        visibility: hidden;
        height: 0px;
    }
}