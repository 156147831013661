.content {
    margin-top: 84px;
    display: inline-block;
    width: 100%;
}

.inline-tabs {
    font-size: 16px;
}

.inline-tabs ul {
    line-height: 40px;
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.inline-tabs ul li {
    display: inline-block;
    font-size: 16px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.4);
    margin-right: 20px;
    text-decoration: none;
    cursor: pointer;
}

.inline-tabs ul li .active {
    color: black;
}

.section {
    padding: 20px 0px;
    border-bottom: 1px solid #FBF6F6;
    width: 100%;
    display: inline-block;
}

.section .dispute,
.content .no-region {
    color: red;
}

.section.tabs h2 {
    display: inline-block;
    margin-right: 20px;
}

.section h2 {
    font-weight: normal;
    font-size: 20px;
}

.section h2 .comment-status {
    color: #ea592c;
    font-size: 16px;
    vertical-align: top;
    transform: rotateY(180deg);
    margin-left: 5px;
}

.delivery-tabs h2 {
    cursor: pointer;
}

.delivery-tabs h2.inactive {
    color: rgba(0,0,0,0.4);
}

.actions-menu .icons-col {
    text-align: right;
}

.section.audit-info {
    padding-bottom: 50px;
    margin: 0px;
}

.section .summary {
    margin-bottom: 30px;
}

.section .summary .total {
    color: #ea592c;
}

.section .summary .grand-balance {
    color: #ea592c;
    font-weight: bold;
}

.number-col {
    text-align: right;
}

.edit-service-dialog {
    height: 100%;
    width: 0px;
    background-color: #fff;
    transition: right .6s;
    z-index: 100;
    position: fixed;
    top: 0px;
    right: -500px;
    overflow-y: scroll;
}

.edit-service-dialog.active {
    right: 0px;
    width: 100%;
}

.edit-service-dialog .dialog-content {
    padding: 40px 40px 0;
}

.edit-service-dialog .dialog-content h3 {
    margin-top: 0px;
    margin-bottom: 20px;
    font-weight: normal;
}

.edit-service-dialog .dialog-actions {
    padding: 0 40px 40px;
    text-align: right;
}

.edit-service-dialog .dialog-actions div {
    margin-left: 20px;
    display: inline-flex;
    vertical-align: middle;
    align-items: center;
}

.edit-service-dialog .dialog-actions .icon {
    vertical-align: middle;
}

.edit-service-dialog .dialog-actions .done {
    color: #ea592c;
}

.edit-service-dialog .dialog-actions .close, .edit-service-dialog .dialog-actions .delete {
    color: rgba(0,0,0,0.4);
}

.edit-service-dialog input[name='total']:disabled {
    color: #ea592c;
}

.lines-container {
    margin-bottom: 30px;
    position: relative;
}

.product-lines, .service-lines {
    width: 100%;
}

.product-lines .product-group-header, 
.service-lines .service-group-header {
    border: 1px solid #ea592c;
    border-radius: 16px;
    height: 32px;
    background-color: #fff;
    color: #ea592c;
    display: inline-flex;
    width: 100%;
    font-size: 13px;
    margin-bottom: 10px;
    overflow: hidden;
}

.product-lines .product-group-header span, 
.service-lines .service-group-header span {
    display: flex;
    align-items: center;
    user-select: none;
    white-space: nowrap;
    padding-left: 12px;
    padding-right: 12px;
}

.product-lines .product-body, 
.service-lines .service-body {
    margin-bottom: 10px;
    border: 1px solid #eee;
    border-radius: 16px;
    padding: 16px;
}

.product-lines .product-body .item-name, 
.service-lines .service-body .item-name {
    display: flex;
    align-items: center;
}

.product-lines .product-body .product-label, 
.service-lines .service-body .service-label {
    color: #aaa;
    padding: 6px 0;
}

.product-lines .product-body .icon, 
.service-lines .service-body .icon {
    text-align: right;
    cursor: pointer;
}

.product-lines .split-info,
.service-lines .split-info,
.payments .split-info {
    margin: 5px 0;
    font-size: 12px;
    color: #EA592C;
    border: 1px solid #eee;
    border-radius: 16px;
    padding: 3px;
    text-align: center;
}

.dialog-content .split-info {
    margin: 0px 0px 15px;
}

.workflow-dialog-content {
    padding-bottom: 10px;
}

.full-dialog .dialog-content .workflow-dialog-content h3{
    margin-bottom: 10px;
}

.workflow-dialog-content h3{
    margin-bottom: 10px;
}

.product-lines .more-info,
.service-lines .more-info {
    font-size: 12px;
    color: #EA592C;
    text-align: right;
}

.product-lines .cloned-info {
    font-size: 12px;
    color: red;
    text-align: left;
}

.payments {
    padding-bottom: 20px;
}

.related-orders ul {
    list-style: none;
    padding: 0;
    margin-bottom: 30px;
    margin-top: 8px;
}

.related-orders ul li {
    display: inline-block;
    margin-right: 8px;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.54)
}

.related-orders label {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.54);
}

.related-orders .order-links {
    color: #ea592c;
    vertical-align: middle;
}

.product-lines .product-note,
.service-lines .service-note {
    margin-top: 10px;
}

.approval-notes {
    list-style: none;
    margin: 0 0 30px;
    padding: 0;
    color: #ea592c;
    font-size: 12px;
}

.approval-notes li {
    margin-bottom: 5px;
}

.result-item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    min-height: 50px;
    font-size: 14px;
}

.result-item .icon {
    display: inline-flex;
    align-items: center;
}

.sub-text {
    color: #9F9F9F;
}

.item-name .sub-text {
    margin-left: 5px;
    margin-right: 5px;
}

.po-raised-icon {
    color: brown;
    font-size: 18px !important;
}

.warehouse-div {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.warehouse-icon {
    color: brown;
    font-size: 18px !important;
    margin-right: 4px;
}

.warehouse-text {
    color: brown;
    font-size: 12px !important;
}

.full-dialog {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 101;
    background-color: rgba(251,246,246,1);
    top: 0;
    left: 0;
    overflow-y: scroll;
}

.full-dialog .dialog-content {
    padding: 50px;
    line-height: 24px;
    max-width: 480px;
    margin: 0 auto;
}

.full-dialog .dialog-content h3 {
    margin-top: 0px;
    margin-bottom: 40px;
    font-weight: normal;
}

.full-dialog .dialog-actions {
    text-align: right;
    max-width: 480px;
    margin: 0 auto;
}

.full-dialog .dialog-actions div {
    margin-left: 20px;
    display: inline-flex;
    vertical-align: middle;
    align-items: center;
}

.full-dialog .dialog-actions .icon {
    vertical-align: middle;
}

.full-dialog .dialog-actions .done {
    color: #ea592c;
}

.full-dialog .dialog-actions .close {
    color: rgba(0,0,0,0.4);
}

.reason-select {
    margin-bottom: 30px;
    width: 315px;
    display: block
}

.add-product-button {
    margin-right: 5px;
    margin-bottom: 20px;
}

.add-service-button {
    margin-right: 5px;
    margin-bottom: 20px;
}

.add-product-button p {
    color: rgba(0, 0, 0, 0.54);
    margin: 0;
    font-size: 12px;
    line-height: 16px;
    margin-top: 10px;
}

.payment-lines {
    margin-bottom: 30px;
}

.payment-lines .payment-item {
    margin-bottom: 10px;
}

.payment-lines .payment-type {
    font-weight: bold;
    padding-bottom: 15px;
    align-self: center;
}

.payment-lines .payment-line {
    margin-bottom: 10px;
    border: 1px solid #eee;
    border-radius: 16px;
    padding: 16px;
}

.payment-lines .payment-line .icon {
    text-align: right;
}

.payment-lines  .payment-header{
    border-bottom: 1px solid #eee;
    padding: 16px 0;
}


.actions-menu .icon-container.active.view-quote {
    bottom: 220px;
}

.event-items {
    display: block;
    width: 100%;
}

.event-item {
    border-top: 1px solid #f1f3f4;
    font-size: 12px;
    color: rgba(0,0,0,0.4);
    padding: 20px 0;
    width: 100%;
    display: inline-block;
}

.po-lines {
    margin-bottom: 20px;
    border: 1px solid #eee;
    border-radius: 16px;
    padding: 16px;
}

.po-lines .po-line {
    margin-top: 10px;
}

.po-lines .check {
    text-align: right;
}

.po-line .product-code {
    font-size: smaller;
    color: #9F9F9F;
    margin-right: 5px;
}

.po-line .product-name {
    color: #000;
    margin-right: 5px;
}

.po-lines .item {
    padding: 0px;
    padding-bottom: 10px;
}

.submit-quote-dialog .service-lines {
    margin-top: 30px;
}

.full-dialog .summary {
    line-height: 32px;
}

.full-dialog .warn {
    color: #EA592C;
}

.full-dialog .powarn {
    color: #EA592C;
    font-size: 17px;
    font-weight: bold;
}

.stockingpoint-disabled-warning {
    display: inline;
    vertical-align: -webkit-baseline-middle;
}

.order-validation-error {
    color: #f44336;
}

@media (min-width: 1280px) {
    .payment-lines .payment-line {
        margin-bottom: 0px;
        border: 0px;
        padding: 16px 0 0 0;
    }

    .payment-lines .payment-line .icon {
        padding-right: 16px;
    }

    .payment-lines .payment-type {
        font-weight: normal;
        padding-bottom: 0px;
    }
}

@media (min-width: 960px) {
    .edit-service-dialog.active {
        right: 0px;
        width: 385px;
        box-shadow: rgba(51, 51, 51, 0.16) 0px 0px 16px 0px;
        border-radius: 16px 0px 0px;
    }
}

@media (min-width: 600px) {
    .product-lines .more-info,
    .service-lines .more-info {
        text-align: left;
    }
    .result-item {
        font-size: unset;
    }
}

