.reports {
    width: 500px;
    margin-top: 100px;
    padding-top: 20px;
}

.clearTextStyle {
    margin-top: 20px;
}


.print-action {
    color: white;
    background-color: #EA592C;
    padding: 5px;
    border-radius: 3px;
    padding-left: 52px;
    padding-right: 52px;
    
}

