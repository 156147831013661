.lg-nav {
    height: 100%;
    color: #fff;
    font-size: 16px;
    border-radius: 0 0 16px 0;
    background-color: #EA592C;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.08), 0 8px 16px 0 rgba(0,0,0,0.08), 0 16px 32px 0 rgba(0,0,0,0.08);
    position: fixed;
    width: 266px;
    top: 0;
    left: -500px;
    transition: left .6s;
    z-index: 100;
}

.lg-nav .inner {
    padding: 32px 40px;
}

.lg-nav .logo-container img {
    width: 70%;
    margin: 0 auto;
    display: block;
}

.version-tag {
    font-size: smaller;
    position: absolute;
    bottom: 0;
}


.lg-nav .logo-container {
    bottom: 45px;
    position: absolute;
}

.lg-nav .username {
    font-size: 12px;
    margin-top: 5px;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    color: rgba(255,255,255,.8);
}

.lg-nav h2 {
    font-weight: normal;
    margin: 0;
    font-size: 24px;
}

.lg-nav.active {
    left: 0px;
    height: 100%;
    width: 266px;
}

.lg-nav ul {
    padding: 0;
}

.lg-nav li {
    margin: 20px 0;
    list-style: none;
}

.lg-nav li a {
    color: #fff;
    text-decoration: none;
    width: 100%;
    display: inline-block;
    padding: 10px 0;
}

@media (min-width: 1280px) {
    .lg-nav {
        left: 0;
        max-width: 235px;
    }

    .lg-nav .inner {
        padding: 48px;
    }

    .lg-nav.active {
        left: 0;
        height: 100%;
    }

    .lg-nav li.active::before {
        content: "\2022";
        position: absolute;
        margin-left: -15px;
        padding: 10px 0;
    }
}

@media (max-height: 670px) {
    .lg-nav .logo-container {
        display: none;
    }
}
