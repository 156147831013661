.sales {
    width: 100%;
    margin-top: 174px;
}

.sales .item {
    border-bottom: 1px solid #f1f3f4;
    font-size: 12px;
    color: rgba(0,0,0,0.4);
    padding: 40px 0;
    cursor: pointer;
}

.sales .item.cancelled, 
.sales .item.cancelled .number,
.sales .item.cancelled .icon  {
    background-color: #fafafa;
    color: rgba(0,0,0,0.4);
}

.sales .item .number {
    font-size: 16px;
    color: #333333;
    margin-bottom: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.sales .item .customer > span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    display: inline-block;
    margin: 0px;
}

.sales .item .icon,
.sales .item .date {
    text-align: right;
}

.sales .item .date.green {
    color: #2CEAA8;
}

.sales .item .date.red,
.sales .item .indispute {
    color: red;
}

.sales .tba-banner {
    position: sticky;
    bottom: 0px;
    background-color: #EA592C;
    height: 64px;
    line-height: 64px;
    color: #fff;
    font-size: 18px;
}
.sales .tba-order {
    background-color: #fde4dc;
}

.action-container {
    height: 56px;	
    width: 56px;	
    border-radius: 16px 0 16px 0;	
    background-color: #EA592C;	
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.08), 0 8px 16px 0 rgba(0,0,0,0.08), 0 26px 32px 0 rgba(0,0,0,0.08);
    position: fixed;
    right: 15px;
    bottom: 15px;
    text-align: center;
}

.action-container .icon {
    color: #fff;
    margin: 15px;
}

.search-lg {
    display: none;
}

@media (min-width: 1280px) {
    .search-lg {
        display: inline-block;
        text-align: left;
        padding-top: 45px;
    }
}