.settings {
    width: 100%;
    margin-top: 84px;
}

.settings h2 {
    display: inline-block;
}

@media (min-width: 1280px) {
    .settings {
        margin-top: 0px;
    }
}