.address-search {
    margin-bottom: 30px;
}

.address-search .no-map {
    margin-bottom: 0;
}

.address-map p {
    height: 24px;
    font-size: 12px;
    color: #aaa;
    line-height: 24px;
}