.no-records {
    border-bottom: 1px solid #f1f3f4;
    font-size: 12px;
    color: rgba(0,0,0,0.4);
    padding: 40px 0;
}

.no-records .message {
    padding-top: 10px;
    padding-bottom: 10px;
}