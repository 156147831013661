.ranges, .range {
    margin-top: 84px;
}

.range .name {
    margin-top: 30px;
}

.ranges .item {
    border-bottom: 1px solid #f1f3f4;
    font-size: 12px;
    color: rgba(0,0,0,0.4);
    padding: 20px 0;
    cursor: pointer;
}

.ranges .item .name {
    font-size: 16px;
    color: #333333;
}

.ranges .summary ul {
    list-style-type: none;
    padding: 0;
    margin-bottom: 0;
}

.ranges .summary li {
    display: inline-block;
    padding: 0 0.6em 0 0;
}

.ranges .tier-name {
    font-weight: bold;
}

.markups {
    background-color: #f5efef;
}

.markups .item {
    border-bottom: 1px solid #fbf6f6;
    padding: 20px 0;
    font-size: 18px;
    color: rgb(51, 51, 51)
}

.markups .item input {
    background-color: #fbf6f6;
    display: inline-block;
    width: 70px;
}

@media (min-width: 1280px) {
    .ranges {
        margin-top: 0px;
    }
}