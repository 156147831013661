.price-list {
    width: 100%;
    margin-top: 84px;
    padding-top: 20px;
}

.prices > div {
    overflow: hidden;
}

.prices .MUIDataTableHeadCell-fixedHeader {
    z-index: 0;
}

.price-list .filters {
    margin-bottom: 30px;
}